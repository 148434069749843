// ! 之前版本，所有内容都在 fieldInfoList.fieldInfo 是一个JSON字符串，内容不可控不可靠
// ! 现更改为，在前端平铺所有字段，后端只记录字段的 显示/隐藏、必填/非必填。
// ! 字段若存在校验规则，则由前端提供。

// *自然数
const naturalNumber = (v, name) => {
  const reg = /^(?:0|(?:-?[1-9]\d*))$/;
  if (v && +v < 0) throw `${name}大于等于零的数字`;
  else if (v && !reg.test(v)) throw `${name}请输入整数`;
  else return true;
};
export default {
  BASIC_INFORMATION: {
    customer_name: {
      type: "text",
      label: "姓名",
      key: "customer_name",
      validator: (v) => {
        const reg = /^(?:[\u4e00-\u9fa5·]{2,20})$/;
        if (v && !reg.test(v)) throw "请输入正确的姓名";
        else return true;
      }
    },
    phone: {
      type: "text",
      label: "手机号",
      key: "phone",
      validator: (v) => {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
        if (v && !reg.test(v)) throw "请输入正确的手机号";
        else return true;
      }
    },
    cert_no: {
      type: "text",
      label: "身份证号",
      key: "cert_no",
      validator: (v) => {
        const reg =
          /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
        if (v && !reg.test(v)) throw "请输入正确的身份证号";
        else return true;
      }
    },
    sex: { type: "select", label: "性别", key: "sex" },
    birthday: { type: "date", label: "生日", key: "birthday" },
    expiry_date: { type: "date", label: "身份证有效期", key: "expiry_date" },
    nation: { type: "select", label: "民族", key: "nation" },
    licensing_authority: {
      type: "text",
      label: "发证机关",
      key: "licensing_authority",
      maxlength: 32
    },
    issue_date: { type: "date", label: "签发日期", key: "issue_date" },
    permanentz_address: {
      type: "text",
      label: "户籍地址",
      key: "permanentz_address",
      maxlength: 100
    },
    education_status: {
      type: "select",
      label: "教育程度",
      key: "education_status"
    },
    is_local_person: {
      type: "select",
      label: "是否本地人",
      key: "is_local_person"
    },
    has_local_house: {
      type: "select",
      label: "本地是否有房产",
      key: "has_local_house"
    },
    local_reside_years: {
      type: "text",
      label: "居住年限",
      key: "local_reside_years",
      validator: naturalNumber
    },
    reside_type: { type: "select", label: "居住性质", key: "reside_type" },
    addr_type: { type: "select", label: "居住地址类型", key: "addr_type" },
    addr_code: { type: "region", label: "居住地区", key: "addr_code" },
    reside_address: {
      type: "text",
      label: "详细居住地址",
      key: "reside_address",
      maxlength: 100
    },
    marital_status: {
      type: "select",
      label: "婚姻状况",
      key: "marital_status"
    },
    work_type: { type: "select", label: "就业分类", key: "work_type" }
  },
  WORK_INFORMATION: {
    work_unit: {
      type: "text",
      label: "工作单位名称",
      key: "work_unit",
      maxlength: 32
    },
    lice_id: {
      type: "text",
      label: "统一社会信用代码",
      key: "lice_id",
      validator: (v) => {
        const reg = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/;
        if (v && !reg.test(v)) throw "请输入正确的统一社会信用代码";
        else return true;
      }
    },
    brtype: { type: "select", label: "工商登记类型", key: "brtype" },
    birthday: { type: "date", label: "成立日期", key: "birthday" },
    occupation: { type: "text", label: "职业", key: "occupation" },
    unit_address: {
      type: "text",
      label: "单位地址",
      key: "unit_address",
      maxlength: 100
    },
    entry_time: { type: "date", label: "入职时间", key: "entry_time" },
    work_years: {
      type: "text",
      label: "工作年限",
      key: "work_years",
      validator: naturalNumber
    },
    duty: { type: "text", label: "职务", key: "duty", maxlength: 32 },
    annual_income: {
      type: "amount",
      label: "年收入（元）",
      key: "annual_income",
      unit: "元"
    },
    takings: {
      type: "amount",
      label: "营业年收入（元）",
      key: "takings",
      unit: "元"
    },
    social_insurance_radix: {
      type: "amount",
      label: "社保基数",
      key: "social_insurance_radix",
      unit: "元"
    },
    accumulation_fund_radix: {
      type: "amount",
      label: "公积金基数",
      key: "accumulation_fund_radix",
      unit: "元"
    },
    accumulation_fund_ratio: {
      type: "text",
      label: "公积金比例（%）",
      key: "accumulation_fund_ratio",
      validator: (v) => {
        const reg = /^(?:0|(?:-?[1-9]\d*))$/;
        if (v && !reg.test(v)) throw "请输入正确的公积金比例";
        else if (v && +v > 100) throw "请输入100以内的公积金比例";
        else if (v && +v < 0) throw "请输入大于0的公积金比例";
        else return true;
      }
    },
    tech_title: { type: "select", label: "职称", key: "tech_title" },
    comany_addr_code: {
      type: "region",
      label: "公司地区",
      key: "comany_addr_code"
    }
  },
  VEHICLE_PRODUCTION_INFORMATION: {
    vehicle_vin: {
      type: "text",
      label: "车架号(vin)",
      key: "vehicle_vin",
      validator: (v) => {
        const reg = /^[A-HJ-NPR-Z\d]{8}[X\d][A-HJ-NPR-Z\d]{3}\d{5}$/;
        if (v && !reg.test(v)) throw "请输入正确的车架号";
        else return true;
      }
    },
    car_licences: {
      type: "text",
      label: "车牌号",
      key: "car_licences",
      validator: (v) => {
        const reg =
          /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/;
        if (v && !reg.test(v)) throw "请输入正确的车牌号";
        else return true;
      }
    },
    car_engine_no: {
      type: "text",
      label: "发动机号",
      key: "car_engine_no",
      maxlength: 32
    },
    car_licences_area: {
      type: "region",
      label: "上牌地区",
      key: "car_licences_area"
    },
    assess_amount: {
      type: "amount",
      label: "评估金额（元）",
      key: "assess_amount",
      unit: "元"
    },
    car_full_name_model: {
      type: "text",
      label: "品牌车型",
      key: "car_full_name_model",
      maxlength: 32
    },
    car_pricing: {
      type: "amount",
      label: "新车指导价（元）",
      key: "car_pricing",
      unit: "元"
    },
    car_factory_type: {
      type: "text",
      label: "厂牌型号",
      key: "car_factory_type",
      maxlength: 32
    },
    car_owner: {
      type: "text",
      label: "车辆所有人",
      key: "car_owner",
      maxlength: 32
    },
    car_factory_date: {
      type: "date",
      label: "出厂日期",
      key: "car_factory_date"
    },
    car_reg_date: { type: "date", label: "上牌日期", key: "car_reg_date" },
    car_km: {
      type: "text",
      label: "公里",
      key: "car_km",
      validator: naturalNumber
    },
    car_color: { type: "text", label: "颜色", key: "car_color", maxlength: 32 },
    car_kw: {
      type: "text",
      label: "功率",
      key: "car_kw",
      validator: naturalNumber
    },
    car_ml: {
      type: "text",
      label: "排量",
      key: "car_ml",
      validator: naturalNumber
    },
    car_usetype: { type: "select", label: "使用性质", key: "car_usetype" },
    car_maintenance: {
      type: "select",
      label: "保养状况",
      key: "car_maintenance"
    },
    accidenttype: { type: "select", label: "事故类型", key: "accidenttype" },
    car_appearance: {
      type: "text",
      label: "车辆外观",
      key: "car_appearance",
      maxlength: 100
    },
    car_engine_nacelle: {
      type: "text",
      label: "发动机舱",
      key: "car_engine_nacelle",
      maxlength: 100
    },
    car_structure: {
      type: "text",
      label: "车身架构",
      key: "car_structure",
      maxlength: 100
    },
    car_inner: {
      type: "text",
      label: "车身内饰",
      key: "car_inner",
      maxlength: 100
    },
    car_remark: {
      type: "text",
      label: "备注",
      key: "car_remark",
      maxlength: 100
    },
    used_month: {
      type: "text",
      // label: "车龄（月）",
      label: "车龄（年）", // 由月改为年，但是只是展示。提交的时候换算成月 憨批操作。
      key: "used_month",
      validator: naturalNumber
    },
    report_date: { type: "date", label: "评估日期", key: "report_date" },
    ss_brand: { type: "text", label: "品牌", key: "ss_brand", maxlength: 100 },
    ss_serial: {
      type: "text",
      label: "车系",
      key: "ss_serial",
      maxlength: 100
    },
    ss_model: { type: "text", label: "车型", key: "ss_model", maxlength: 100 }
  },
  PROPERTY_INFORMATION: {
    house_cert_no: {
      type: "text",
      label: "房产证号",
      key: "house_cert_no",
      maxlength: 32
    },
    have_house: { type: "select", label: "是否有房产", key: "have_house" },
    house_nature: { type: "select", label: "房产性质", key: "house_nature" },
    house_status: { type: "select", label: "房产状态", key: "house_status" },
    house_address: {
      type: "text",
      label: "房产地址",
      key: "house_address",
      maxlength: 100
    },
    house_acreage: {
      type: "text",
      label: "房产面积（m²）",
      key: "house_acreage",
      validator: naturalNumber
    },
    house_owner: {
      type: "text",
      label: "房产权利人",
      key: "house_owner",
      maxlength: 32
    },
    house_situation: {
      type: "text",
      label: "共有情况",
      key: "house_situation",
      maxlength: 100
    }
  },
  // 申请设备
  APPLY_EQUIPMENT: {
    model: {
      type: "text",
      label: "型号",
      key: "model"
    },
    device_capacity: {
      type: "text",
      label: "容量",
      key: "device_capacity"
    },
    device_color: {
      type: "text",
      label: "颜色",
      key: "device_color"
    },
    device_number: {
      type: "text",
      label: "数量",
      key: "device_number"
    },
    device_sn: {
      type: "text",
      label: "S/N号",
      key: "device_sn"
    },
    device_imel: {
      type: "text",
      label: "IMEI号",
      key: "device_imel"
    },
    single_contract_price: {
      type: "amount",
      label: "单件签约价",
      key: "single_contract_price",
      unit: "元"
    },
    device_deposit: {
      type: "amount",
      label: "押金",
      key: "device_deposit",
      unit: "元"
    },
    device_rent: {
      type: "amount",
      label: "租金",
      key: "device_rent",
      unit: "元"
    },
    device_balance_payment: {
      type: "amount",
      label: "期满买断尾款",
      key: "device_balance_payment",
      unit: "元"
    }
  }
};
